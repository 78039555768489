<template>
  <div class="content gradient" style="margin-top: -50px">
    <div class="text-center">
      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (8am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />

      <br>
      <br>
      <h1
        class="tulum-party-title"
        style="text-shadow: #23bac4 1px   4px 11px;"

      >
        TULUM
        <br>BEACH CLUBS
      </h1>

      <h3
        class="tulum-party-subtitle gradient"
        style="
          text-transform: uppercase;
          font-size: 0.9rem;
          letter-spacing: 0.1rem;
          margin-top: -20px;
          font-weight: bold;
          color: mediumaquamarine !important;
          text-shadow: black 1px   1px 1px;
          background-color: black !important;
        "
      >
        Tulum best clubs to spend your day.
      </h3>
      <p style="font-size: 0.9rem;margin-top: 10px">  Lose no Time, The Beach Await ! <br><strong> Make Your Day
        Unforgettable!</strong></p>



      <br>
      <br>
      <card
        class="card text-center "
        style="
        background: linear-gradient(#23bac4, #66d0dd);
        z-index: 1;width: 90%;margin-left: auto;margin-right: auto;left: 0;right: 0;margin-bottom: 50px;padding: 10px;">
        <p style="font-size: 1.2rem"><strong>Unleash the Magic of Tulum's Blue Beaches</strong></p>

        <br>

          <img
            v-for="(row, idx) in venues.slice(0,6)"
            v-bind:alt="row.title"
            v-lazy="row.metadata.main_image.imgix_url + '?fit=clamp&h=50&w=50'"
            height="50px"
            width="50px"
            v-if="row.metadata.main_image"
          />
        <br>
        <br>
        <br>
        <div class="col-12">
          <RsvpBeachClubsViaDialog ></RsvpBeachClubsViaDialog>
        </div>


        <br>

        <small style="color: black">
          We operate from 8AM - 11PM / Answer within 15 mins</small
        >
        <p style="font-size: 0.7rem;font-weight: bold">
          Your All-In-One Solution for a Successful Celebration!
        </p>
        <img class=" d-none d-sm-inline"
             v-lazy="birds"
             height="130px" style="z-index: -5;opacity: 0.7;margin: 20px">
      </card>


      <div class="row gradient">

        <img
          v-lazy="bgPalm"
          class="d-inline-sm d-md-none"
          v-if="showPalms"
          alt="tulum.party crazy palm"
          style="
            position: fixed;
            left: 5%;
            bottom: 5%;
            opacity: 0.1;
            width: 100%;
            z-index: 0;
          "
        />
        <div
          class="col-sm-12 col-md-6"
          v-for="(row, idx) in venues"
          :key="idx"
          style="
            box-shadow: #000000 10px 10px 2px;
            padding: 30px;
            flex-flow: column;
          "
        >
          <img
            v-bind:alt="row.title"
            v-lazy="row.metadata.main_image.imgix_url + '?h=430'"
            height="430px"
            v-if="row.metadata.main_image"
            style="box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 60%); border-top: 13px solid black; border-bottom: 13px solid black;border-right: 1px solid black;border-left: 1px solid black"
          />
          <br/>
          <br/>
          <h5
            class="tulum-party-title"

            :style="{color: idx < 2 ? 'aquamarine'  : 'mediumaquamarine'}"
            style="margin-top: -1px;           text-shadow: black 1px   1px 1px;
"
          >
            {{ row.title }}
          </h5>

          <div
            style="
              color: black !important;
              margin-bottom: 10px;
              background-color: black;
              opacity: 0.5;
              padding: 10px;
              border-radius: 5px;
            "
          >

            <div v-html="row.metadata.description"></div>
            <Collapse>
              <CollapseItem title="Details">

                <div v-html="row.content"></div>

              </CollapseItem>

            </Collapse>
          </div>
          <!--      DESKTOP -->
          <br/>
          <RsvpBeachClubsViaDialog :row="row"></RsvpBeachClubsViaDialog>

        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';

const VueWhatsappWidget = () => import( /* webpackChunkName: "vue-whatsapp-widget" */'../../components/WhatsAppWidget/Widget');
const RsvpBeachClubsViaDialog = () => import( /* webpackChunkName: "RsvpDialogs" */'../../components/Reservations/RsvpBeachClubsViaDialog');

export default {
  name: 'BeachClubs.vue',
  data: () => {
    return {
      showPalms: false,
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/031c4bf0-e6e8-11ec-9447-f98173199613-telegram-cloud-photo-size-1-4972161472985672258-y.jpg',
      birds:
        "https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png"
    };
  },

  metaInfo: () => ({
    title: 'Tulum Beach & Day Clubs. Information & Reservations.',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.tulum.party/tulum-day-beach-clubs',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Top Day/Night Clubs @ Tulum Mexico. Information & Reservations.',
      },
      {
        name: 'keywords',
        content:
          'Tulum, Day CLub, clubs, beach clubs, Today,  2022, Papaya Playa, Vagalume, Events, Bagatelle, Taboo, Tantra',
      },
      {
        property: 'og:title',
        content: `Tulum Beach & Day Clubs. Information & Reservations.`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'Tulum'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Top Beach/Day Clubs @ Tulum Mexico`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=300&w=300&fit=clamp',
      },
    ],
  }),
  components: {
    RsvpBeachClubsViaDialog,
    CollapseItem,
    Collapse,
    VueWhatsappWidget,
  },
  computed: {
    venues: {
      get() {
        return this.$store.state.beach_clubs;
      },
    },
    faqs: {
      get() {
        return this.$store.state.faqs_category;
      },
    },
  },
  created() {
    this.$store.dispatch('getBeachClubs', {start: 0, limit: 12});
    this.$store.dispatch('getFaqsByCategory', 'Clubs');
    window.history.scrollRestoration = 'manual';
  },
  updated() {
    setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    onJoinInstagramClick() {
      window.ga('send', 'event', 'instagram', 'JOIN', 'Main', 5);
      this.$rollbar.info('Instagram join');
    },
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
